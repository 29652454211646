import { Children, cloneElement } from "react";
import { twMerge } from "tailwind-merge";

import PropTypes from "prop-types";

const GridLayout = ({ children, className, classNames, ...props }) => (
  <div
    className={twMerge(
      "grid grid-cols-2 md:grid-cols-4 lg:grid-cols-10 xl:grid-cols-12 gap-6 grid-flow-dense auto-rows-[170px]",
      className,
    )}
    {...props}
  >
    {Children.map(children, (child, index) =>
      cloneElement(child, {
        className: twMerge(
          "grid-card shadow-xs transition-all duration-100 ease-in-out border border-gray-lightest",
          classNames[index],
        ),
      }),
    )}
  </div>
);

GridLayout.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export default GridLayout;
